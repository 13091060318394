import React, {useState, useCallback, useEffect} from 'react';
import {
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {loadStat, PATIENT} from '~/features/Patient/slice';
import {STAT_SCALE} from '~/types/types';
import {RootState} from '~/app/rootReducer';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import _ from 'lodash';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {renderDateTableHeader} from '~/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  filterTitle: {
    fontSize: '1rem',
    borderRightWidth: 3,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  filterContent: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  chartContainer: {
    width: '100%',
    height: '30vh',
  },
}));

const PatientStatScreen = ({statScale, viewType, date}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const stat = useSelector((state: RootState) => state[PATIENT].stat);

  const [data, setData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();

  useEffect(() => {
    const result = _.keys(stat).map((key) => {
      const values = _.chain(stat[key])
        .reduce((acc, v) => {
          return {
            ...acc,
            ...v,
          };
        }, {})
        .mapKeys((_value, key) => `${key}`)
        .value();
      return {
        name: key,
        ...values,
      };
    });
    setData(result);
  }, [stat]);

  useEffect(() => {
    const datetime = moment(date);
    if (statScale === STAT_SCALE.MONTH) {
      datetime.month(6);
    }
    const request = {
      scale: statScale,
      datetime: datetime,
    };
    dispatch(loadStat(request));
  }, [dispatch, date, statScale]);

  const renderTableHeader = useCallback(() => {
    return renderDateTableHeader(viewType, stat);
  }, [viewType, stat]);

  return (
    <>
      <Paper>
        <div className={classes.chartContainer}>
          <ResponsiveContainer>
            <BarChart
              data={data}
              margin={{top: 20, right: 20, left: 0, bottom: 5}}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="1" stackId="a" fill="#4472c4" name="1급" />
              <Bar dataKey="2" stackId="a" fill="#ed7d31" name="2급" />
              <Bar dataKey="3" stackId="a" fill="#a5a5a5" name="3급" />
              <Bar dataKey="4" stackId="a" fill="#ffc000" name="4급" />
              <Bar dataKey="5" stackId="a" fill="#5b9bd5" name="5급" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Paper>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {renderTableHeader()}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.range(1, 6).map((grade) => {
              return (
                <TableRow key={`row-${grade}`}>
                  <TableCell>{`${grade}급`}</TableCell>
                  {_.map(data, (d) => (
                    <TableCell key={`row-body-${d.name}`} align="right">
                      {d[grade]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>합계</TableCell>
              {_.map(data, (d) => (
                <TableCell key={`row-sum-${d.name}`} align="right">
                  {_.sum(_.range(1, 6).map((grade) => d[grade]))}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(PatientStatScreen);
