import createSagaMiddleware from 'redux-saga';
import {all} from 'redux-saga/effects';
import {configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore, createTransform} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer, {RootState} from './rootReducer';
import watchScheduleStat from '~/features/ScheduleStat/saga';
import watchDutyStat from '~/features/DutyStat/saga';
import watchSchedule from '~/features/Schedule/saga';
import {LOGIN} from '~/features/Login/slice';
import {watchLogin} from '~/features/Login/saga';
import {watchAccountType} from '~/features/AccountType/saga';
import {watchEmployees} from '~/features/Employee/saga';
import {watchAccounts} from '~/features/Account/saga';
import watchPatient from '~/features/Patient/saga';
import watchBEP from '~/features/BEP/saga';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [LOGIN],
  transforms: [
    createTransform((inboundState: any, _key) => {
      return {...inboundState, authenticated: false};
    }),
  ],
};

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

function* rootSaga() {
  yield all([
    watchScheduleStat(),
    watchDutyStat(),
    watchSchedule(),
    watchLogin(),
    watchAccountType(),
    watchEmployees(),
    watchAccounts(),
    watchPatient(),
    watchBEP(),
  ]);
}

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware({});
  const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [sagaMiddleware],
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return {store, persistor};
};

export type AppDipatch = ReturnType<typeof createStore>;
export default createStore;
