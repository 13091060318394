import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {NewAccount, Account} from '~/types/types';

type State = {
  loading: boolean;
  accounts: Account[];
};

let initialState: State = {
  loading: false,
  accounts: [],
};

const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    loadAccounts: (state) => {
      state.loading = true;
    },
    saveAccount: (state, action: PayloadAction<NewAccount>) => {
      state.loading = true;
    },
    successAccounts: (state, action: PayloadAction<Account[]>) => {
      state.loading = false;
      state.accounts = action.payload;
    },
  },
});

export const {loadAccounts, saveAccount, successAccounts} = slice.actions;
export const ACCOUNTS = slice.name;
export default slice.reducer;
