import {call, select, takeLatest, put} from 'redux-saga/effects';
import {
  loginRequest,
  LOGIN,
  loginSuccess,
  checkLogin,
  loginFailed,
} from './slice';
import {getJWTAPI, refreshTokenAPI} from '~/api';
import moment from 'moment-timezone';
import {RootState} from '~/app/rootReducer';

function* handleLogin() {
  try {
    const {email, password} = yield select((state: RootState) => state[LOGIN]);
    const resp = yield call(getJWTAPI, email, password);
    yield put(loginSuccess(resp.data));
  } catch (err) {
    console.log('error', err);
    yield put(loginFailed());
  }
}

function* handleCheckLogin() {
  try {
    const {token, payload} = yield select((state: RootState) => state[LOGIN]);
    if (payload) {
      const expire = moment.unix(payload.exp);
      const now = moment();

      console.log(expire, now);

      if (token && expire && moment(expire) > now) {
        //const resp = yield call(refreshTokenAPI, token);
        yield put(loginSuccess({token}));
      } else {
        yield put(loginFailed());
      }
    } else {
      yield put(loginFailed());
    }
  } catch (err) {
    console.log('error', err);
    yield put(loginFailed());
  }
}

export function* watchLogin() {
  yield takeLatest(loginRequest, handleLogin);
  yield takeLatest(checkLogin, handleCheckLogin);
}
