import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment';
import Schedule from '~/models/schedule';
import {STAT_SCALE} from '~/types/types';

export interface RequestSchdules {
  scale: STAT_SCALE;
  date: moment.Moment;
}

type State = {
  loading: boolean;
  schedule: Schedule | null;
  schedules: Schedule[];
};

let initialState: State = {
  loading: false,
  schedule: null,
  schedules: [],
};

const slice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    loadSch: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    loadSchedules: (state, _action: PayloadAction<RequestSchdules>) => {
      state.loading = true;
    },
    successSch: (state, action: PayloadAction<Schedule>) => {
      state.loading = false;
      state.schedule = action.payload;
    },
    successLoadSchedules: (state, action: PayloadAction<Schedule[]>) => {
      state.loading = false;
      state.schedules = action.payload;
    },
  },
});

export const {
  loadSch,
  loadSchedules,
  successSch,
  successLoadSchedules,
} = slice.actions;
export const SCHEDULES = slice.name;
export default slice.reducer;
