import React, {useState, useCallback, useEffect, ReactElement} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Calendar, {Detail} from 'react-calendar';
import {Paper, Typography} from '@material-ui/core';
import {
  ContentRenderer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ReferenceLine,
  ComposedChart,
  ResponsiveContainer,
  Line,
  RectangleProps,
  Legend,
  LabelProps,
} from 'recharts';
import moment from 'moment';
import {
  STAT_SCALE,
  WAGE_MINIMUM_RATIO,
  SCALE_TO_VIEW,
  SCALE_TO_VIEW_2,
} from '~/types/types';
import {
  loadWageStat,
  BEP,
  loadDutyStat,
  loadAccStat,
} from '~/features/BEP/slice';
import {RootState} from '~/app/rootReducer';
import _ from 'lodash';
import PeriodSelector from '~/components/PeriodSelector';
import {ACCOUNT_TYPE, loadAccountType} from '~/features/AccountType/slice';
import StyledCalendar from '~/components/StyledCalendar';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  chartPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    alignItems: 'center',
  },
  filterTitle: {
    fontSize: '1rem',
    borderRightWidth: 3,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  filterContent: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  chartContainer: {
    width: '100%',
    height: 300,
  },
}));

const CustomLabel: ContentRenderer<LabelProps> = (props: LabelProps) => {
  const {x, y, stroke, value} = props;

  return (
    <text
      x={x}
      y={y}
      dy={-20}
      fill={stroke as string}
      fontSize={14}
      textAnchor="middle">
      {value}
    </text>
  );
};

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const duty = useSelector((state: RootState) => state[BEP].duty);
  const wages = useSelector((state: RootState) => state[BEP].wage);
  const account = useSelector((state: RootState) => state[BEP].account);
  const accountTypes = useSelector(
    (state: RootState) => state[ACCOUNT_TYPE].accountTypes,
  );

  const [statScale, setStateScale] = useState<STAT_SCALE>(STAT_SCALE.MONTH);
  const [viewType, setViewType] = useState<Detail>('year');
  const [searchDate, setSearchDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [wageData, setWageData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  const [accData, setAccData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();

  useEffect(() => {
    const outcomes = _.map(accountTypes, (acc) => acc.name).filter(
      (name) => name !== '인건비',
    );
    const data = _.map(outcomes, (name) => {
      let acc: number = 0;
      let prev: number = 0;
      const avg = _.chain(account)
        .values()
        .map((v) => v[name])
        .compact()
        .sum()
        .value();
      if (statScale === STAT_SCALE.YEAR) {
        acc = _.chain(account)
          .values()
          .map((v) => v[name])
          .compact()
          .sum()
          .value();
      } else if (statScale === STAT_SCALE.MONTH) {
        const month = moment(date).format('YYYY-MM');
        const prevm = moment(date).add(-1, 'm').format('YYYY-MM');
        acc = account[month] ? account[month][name] ?? 0 : 0;
        prev = account[prevm] ? account[prevm][name] ?? 0 : 0;
      }

      return {
        name,
        amount: acc,
        average: _.round(avg / 12),
        prev,
      };
    });
    setAccData(data);
  }, [date, accountTypes, account]);

  useEffect(() => {
    const data = _.map(['방문간호', '방문요양', '방문목욕'], (name) => {
      let d: number = 0;
      let w: number = 0;
      if (statScale === STAT_SCALE.YEAR) {
        d = _.chain(duty)
          .values()
          .map((v) => v[name])
          .compact()
          .sum()
          .value();
        w = _.chain(wages)
          .values()
          .map((v) => v[name])
          .compact()
          .sum()
          .value();
      } else if (statScale === STAT_SCALE.MONTH) {
        const month = moment(date).format('YYYY-MM');
        d = duty[month] ? duty[month][name] ?? 0 : 0;
        w = wages[month] ? wages[month][name] ?? 0 : 0;
      }

      const base = _.round(WAGE_MINIMUM_RATIO[name] * 100, 2);
      const ratio = _.round((w / d) * 100, 2);
      const exceed = _.round(ratio - base > 0 ? ratio - base : 0, 2);
      const safe = _.round(100 - exceed - base, 2);
      return {
        name,
        base,
        ratio: isNaN(ratio) ? 0 : ratio,
        exceed,
        safe,
      };
    });
    setWageData(data);
  }, [date, duty, wages]);

  useEffect(() => {
    const datetime = moment(searchDate);
    datetime.month(6);
    const request = {
      scale: STAT_SCALE.MONTH,
      datetime,
    };
    dispatch(loadDutyStat(request));
    dispatch(loadWageStat(request));
    dispatch(loadAccStat(request));
    dispatch(loadAccountType());
  }, [dispatch, searchDate]);

  const handleDate = useCallback((date: Date) => {
    setDate(date);
    setSearchDate((d) => {
      if (d?.getFullYear() !== date.getFullYear()) {
        return date;
      }
      return d;
    });
  }, []);

  const handleScale = (scale: STAT_SCALE) => {
    setStateScale(scale);
    setViewType(SCALE_TO_VIEW[scale]);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.filterTitle}>Analysis Option</div>
        <div className={classes.filterContent}>
          <PeriodSelector value={statScale} onChange={handleScale} />
        </div>
      </Paper>
      <br />
      <StyledCalendar
        value={date}
        onClickYear={handleDate}
        onClickMonth={handleDate}
        onClickDay={handleDate}
        view={viewType}
      />
      <br />
      <Paper className={classes.chartPaper}>
        <Typography variant="h5" gutterBottom>
          인건비 지출
        </Typography>
        <div className={classes.chartContainer}>
          <ResponsiveContainer>
            <ComposedChart
              layout="vertical"
              data={wageData}
              margin={{
                top: 5,
                right: 20,
                left: 20,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Legend />
              <ReferenceLine x={0} stroke="#000" />
              <Bar
                dataKey="base"
                stackId="a"
                barSize={30}
                fill="transparent"
                name=" "
              />
              <Bar
                dataKey="exceed"
                stackId="a"
                barSize={30}
                fill="#8884d8"
                name="초과지급"
              />
              <Bar
                dataKey="safe"
                stackId="a"
                barSize={30}
                fill="red"
                name="법정지급범위"
              />

              <Line
                type="monotone"
                dataKey="base"
                fill="transparent"
                legendType="none"
                strokeWidth={0}
                label={CustomLabel}
              />

              <Line
                type="monotone"
                dataKey="ratio"
                stroke="green"
                fill="green"
                legendType="circle"
                activeDot={{r: 8}}
                dot={{r: 8}}
                strokeWidth={0}
                name="실제지급비율"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Paper>

      <br />
      <Paper className={classes.chartPaper}>
        <Typography variant="h5" gutterBottom>
          운영비
        </Typography>
        <div className={classes.chartContainer}>
          <ResponsiveContainer>
            <ComposedChart
              layout="vertical"
              data={accData}
              margin={{
                top: 5,
                right: 20,
                left: 20,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis dataKey="name" type="category" />
              <Tooltip />
              <Legend />

              <Bar dataKey="amount" barSize={30} name="이번달" fill="#f7c1bf" />
              <Bar
                dataKey="average"
                barSize={30}
                name="연평균"
                fill="#007845"
              />
              <Bar dataKey="prev" barSize={30} name="지난달" fill="#c7ddea" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Paper>
    </>
  );
};
