import {Employee} from '~/types/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type State = {
  loading: boolean;
  employees: Employee[];
};

let initialState: State = {
  loading: false,
  employees: [],
};

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    loadEmployee: (state) => {
      state.loading = true;
    },
    successLoadEmployee: (state, action: PayloadAction<Employee[]>) => {
      state.employees = action.payload;
    },
  },
});

export const {loadEmployee, successLoadEmployee} = slice.actions;
export const EMPLOYEES = slice.name;
export default slice.reducer;
