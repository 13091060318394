import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

interface Props {
  color: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: (props: Props) => props.color,
    marginRight: 8,
  },
}));

const SquareMarker = (props: Props) => {
  const classes = useStyles(props);
  return <div className={classes.root}></div>;
};

export default SquareMarker;
