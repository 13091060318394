import React from 'react';
import {SERVICE_TYPE} from '~/types/types';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';

interface Props {
  value: SERVICE_TYPE;
  onChange: (mode: SERVICE_TYPE) => void;
  all?: boolean;
}

const ServiceSelector = ({value, onChange, all = true}: Props) => {
  return (
    <div>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        onChange={(e) => onChange(parseInt(e.target.value) as SERVICE_TYPE)}
        defaultValue={0}
        value={value}>
        {all && (
          <FormControlLabel
            value={SERVICE_TYPE.TOTAL}
            control={<Radio color="primary" />}
            label="전체"
            labelPlacement="end"
          />
        )}

        <FormControlLabel
          value={SERVICE_TYPE.NURSING}
          control={<Radio color="primary" />}
          label="간호"
          labelPlacement="end"
        />
        <FormControlLabel
          value={SERVICE_TYPE.CARE}
          control={<Radio color="primary" />}
          label="요양"
          labelPlacement="end"
        />
        <FormControlLabel
          value={SERVICE_TYPE.BATH}
          control={<Radio color="primary" />}
          label="목욕"
          labelPlacement="end"
        />
      </RadioGroup>
    </div>
  );
};

export default ServiceSelector;
