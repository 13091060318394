import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {StatReqeustPayload} from '~/types/types';

interface StatResult {
  [key: string]: {[key: number]: number}[];
}

interface State {
  loading: boolean;
  stat: StatResult;
  schTime: StatResult;
  dutyTime: StatResult;
}

const initialState: State = {
  loading: false,
  stat: {},
  schTime: {},
  dutyTime: {},
};

const slice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    loadStat: (state, _action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
    },
    successLoadStat: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.stat = action.payload;
    },
    loadSchStat: (state, _action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
    },
    successLoadSchStat: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.schTime = action.payload;
    },
    loadDutyStat: (state, _action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
    },
    successLoadDutyStat: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.dutyTime = action.payload;
    },
  },
});

export const {
  loadStat,
  loadSchStat,
  loadDutyStat,
  successLoadStat,
  successLoadSchStat,
  successLoadDutyStat,
} = slice.actions;
export const PATIENT = slice.name;
export default slice.reducer;
