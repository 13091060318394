import {call, put, takeLatest} from 'redux-saga/effects';
import {
  loadSch,
  successSch,
  RequestSchdules,
  successLoadSchedules,
  loadSchedules,
} from './slice';
import {getSchedule, getSchedules} from '~/api';
import {PayloadAction} from '@reduxjs/toolkit';

function* handleLoadSchedule({payload}: PayloadAction<number>) {
  try {
    const resp = yield call(getSchedule, payload);
    yield put(successSch(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}
function* handleLoadSchedules({payload}: PayloadAction<RequestSchdules>) {
  try {
    const resp = yield call(getSchedules, payload.scale, payload.date);
    yield put(successLoadSchedules(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchSchedule() {
  yield takeLatest(loadSch, handleLoadSchedule);
  yield takeLatest(loadSchedules, handleLoadSchedules);
}
