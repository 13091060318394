import React from 'react';
import {FormControlLabel, RadioGroup, Radio} from '@material-ui/core';

interface Props {
  value: number;
  onChange: (scale: number) => void;
}

const GradeSelector = ({value, onChange}: Props) => {
  return (
    <div>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        onChange={(e) => onChange(parseInt(e.target.value))}
        defaultValue={0}
        value={value}>
        <FormControlLabel
          value={0}
          control={<Radio color="primary" />}
          label="전체"
          labelPlacement="end"
        />
        <FormControlLabel
          value={1}
          control={<Radio color="primary" />}
          label="1급"
          labelPlacement="end"
        />
        <FormControlLabel
          value={2}
          control={<Radio color="primary" />}
          label="2급"
          labelPlacement="end"
        />
        <FormControlLabel
          value={3}
          control={<Radio color="primary" />}
          label="3급"
          labelPlacement="end"
        />
        <FormControlLabel
          value={4}
          control={<Radio color="primary" />}
          label="4급"
          labelPlacement="end"
        />
        <FormControlLabel
          value={5}
          control={<Radio color="primary" />}
          label="5급"
          labelPlacement="end"
        />
      </RadioGroup>
    </div>
  );
};

export default GradeSelector;
