import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles, Paper} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import moment from 'moment-timezone';

import Graph, {GRAPH_TYPE} from '~/components/Graph';
import {loadSchStatEmployee} from '~/features/ScheduleStat/slice';
import {loadDutyStatEmployee} from '~/features/DutyStat/slice';
import {
  STAT_SCALE,
  STAT_MODE,
  SCALE_TO_VIEW,
  STAT_JOB_TYPE,
} from '~/types/types';
import PeriodSelector from '~/components/PeriodSelector';
import JobSelector from '~/components/JobSelector';
import GraphSelector from '~/components/GraphSelector';
import StyledCalendar from '~/components/StyledCalendar';
import {Detail} from 'react-calendar';

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  filterTitle: {
    fontSize: '1rem',
    borderRightWidth: 3,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  filterContent: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
}));

const PerformanceScreen = () => {
  const classes = useStyles();

  const [statScale, setStateScale] = useState<STAT_SCALE>(STAT_SCALE.MONTH);
  const [statMode] = useState<STAT_MODE>(STAT_MODE.EMPLOYEE);
  const [jobType, setJobType] = useState<STAT_JOB_TYPE>(STAT_JOB_TYPE.TOTAL);
  const [graphType, setGraphType] = useState<GRAPH_TYPE>(GRAPH_TYPE.ALL);
  const [date, setDate] = useState(new Date());
  const [viewType, setViewType] = useState<Detail>('year');
  const dispatch = useDispatch();

  useEffect(() => {
    const request = {
      scale: statScale,
      mode: statMode,
      jobType: jobType,
      datetime: moment(date),
    };

    dispatch(loadSchStatEmployee(request));
    dispatch(loadDutyStatEmployee(request));
  }, [date, statMode, jobType, statScale, dispatch]);

  const handleScale = (scale: STAT_SCALE) => {
    setStateScale(scale);
    setViewType(SCALE_TO_VIEW[scale]);
  };

  const handleDate = useCallback((date: Date) => {
    setDate(date);
  }, []);

  return (
    <>
      <Paper>
        <div className={classes.filter}>
          <div className={classes.filterTitle}>Analysis Option</div>
          <div className={classes.filterContent}>
            <PeriodSelector value={statScale} onChange={handleScale} />
            <JobSelector value={jobType} onChange={(v) => setJobType(v)} />
            <GraphSelector
              value={graphType}
              onChange={(t) => setGraphType(t)}
            />
          </div>
        </div>
      </Paper>
      <br />
      <StyledCalendar
        value={date}
        onClickYear={handleDate}
        onClickMonth={handleDate}
        onClickDay={handleDate}
        view={viewType}
      />
      <br />
      <br />
      {graphType === GRAPH_TYPE.ALL ? (
        <>
          <Graph mode={statMode} type={GRAPH_TYPE.PRICE} />
          <Graph mode={statMode} type={GRAPH_TYPE.HOURS} />
          <Graph mode={statMode} type={GRAPH_TYPE.PPH} />
        </>
      ) : (
        <Graph mode={statMode} type={graphType} />
      )}
    </>
  );
};

export default PerformanceScreen;
