import {call, put, takeLatest, select} from 'redux-saga/effects';
import {
  DUTIES_STAT,
  loadDutyStatTotal,
  successDutyStatTotal,
  loadDutyStatEmployee,
  successDutyStatEmployee,
  successDutyStatNurse,
  successDutyStatCare,
  loadDutyStatNurse,
  loadDutyStatCare,
} from './slice';
import {getDutyStat} from '~/api';

function* handleStatTotal() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getDutyStat, state.request);
    yield put(successDutyStatTotal(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleStatEmployee() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getDutyStat, state.request);
    yield put(successDutyStatEmployee(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleStatNurse() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getDutyStat, state.request);
    yield put(successDutyStatNurse(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleStatCare() {
  try {
    const state = yield select((state) => state[DUTIES_STAT]);
    const resp = yield call(getDutyStat, state.request);
    yield put(successDutyStatCare(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchDutyStat() {
  yield takeLatest(loadDutyStatTotal, handleStatTotal);
  yield takeLatest(loadDutyStatEmployee, handleStatEmployee);
  yield takeLatest(loadDutyStatNurse, handleStatNurse);
  yield takeLatest(loadDutyStatCare, handleStatCare);
}
