import {makeStyles, Paper} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {Detail} from 'react-calendar';
import GradeSelector from '~/components/GradeSelector';
import {GRAPH_TYPE} from '~/components/Graph';
import GraphSelector from '~/components/GraphSelector';
import JobSelector from '~/components/JobSelector';
import PeriodSelector from '~/components/PeriodSelector';
import ServiceSelector from '~/components/ServiceSelector';
import StyledCalendar from '~/components/StyledCalendar';
import {
  STAT_SCALE,
  SCALE_TO_VIEW_2,
  SERVICE_TYPE,
  STAT_JOB_TYPE,
} from '~/types/types';

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  filterTitle: {
    fontSize: '1rem',
    borderRightWidth: 3,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  filterContent: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
}));

export interface StatProps {
  statScale: STAT_SCALE;
  viewType: Detail;
  date: Date;
  service?: SERVICE_TYPE;
  jobType?: STAT_JOB_TYPE;
  graphType?: GRAPH_TYPE;
  grade?: number;
}

export interface StatConfig {
  service?: boolean;
  job?: boolean;
  graph?: boolean;
  grade?: boolean;
}

const withStatTemplate = (
  WrappedComponent: React.FC<any>,
  config?: StatConfig,
) => (props: any) => {
  const classes = useStyles();

  const [date, setDate] = useState(new Date());
  const [statScale, setStateScale] = useState<STAT_SCALE>(STAT_SCALE.MONTH);
  const [viewType, setViewType] = useState<Detail>('decade');
  const [service, setService] = useState<SERVICE_TYPE>(SERVICE_TYPE.NURSING);
  const [jobType, setJobType] = useState<STAT_JOB_TYPE>(STAT_JOB_TYPE.TOTAL);
  const [graphType, setGraphType] = useState<GRAPH_TYPE>(GRAPH_TYPE.ALL);
  const [grade, setGrade] = useState<number>(1);

  const handleScale = (scale: STAT_SCALE) => {
    setStateScale(scale);
    setViewType(SCALE_TO_VIEW_2[scale]);
  };

  const handleDate = useCallback((date: Date) => {
    setDate(date);
  }, []);

  return (
    <>
      <Paper>
        <div className={classes.filter}>
          <div className={classes.filterTitle}>Analysis Option</div>
          <div className={classes.filterContent}>
            <PeriodSelector value={statScale} onChange={handleScale} />
            {config?.grade && (
              <GradeSelector value={grade} onChange={(v) => setGrade(v)} />
            )}
            {config?.service && (
              <ServiceSelector
                all={false}
                value={service}
                onChange={(v) => setService(v)}
              />
            )}
            {config?.job && (
              <JobSelector value={jobType} onChange={(v) => setJobType(v)} />
            )}
            {config?.graph && (
              <GraphSelector
                value={graphType}
                onChange={(t) => setGraphType(t)}
              />
            )}
          </div>
        </div>
      </Paper>
      <br />
      <StyledCalendar
        value={date}
        onClickYear={handleDate}
        onClickMonth={handleDate}
        onClickDay={handleDate}
        onClickDecade={handleDate}
        view={viewType}
      />
      <br />

      <WrappedComponent
        {...props}
        statScale={statScale}
        viewType={viewType}
        date={date}
        service={service}
        jobType={jobType}
        graphType={graphType}
        grade={grade}
      />
    </>
  );
};

export default withStatTemplate;
