import React, {useState, useCallback} from 'react';
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import {
  fade,
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, {TreeItemProps} from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import {useSpring, animated} from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import {TransitionProps} from '@material-ui/core/transitions';
import {
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import {AccountType} from '~/types/types';
import _ from 'lodash';
import {useDispatch} from 'react-redux';
import {
  saveAccountType,
  saveSubAccountType,
} from '~/features/AccountType/slice';

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{width: 14, height: 14}} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{width: 14, height: 14}}
      {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {opacity: 0, transform: 'translate3d(20px,0,0)'},
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
  }),
)((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    accountAdd: {
      flexDirection: 'row',
      alignItems: 'center',
      display: 'flex',
    },
  }),
);

interface Props {
  accountTypes: AccountType[];
}

export default ({accountTypes}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [onAccountAdd, setOnAccountAdd] = useState(false);
  const [onSubAccountAdd, setOnSubAccountAdd] = useState<{
    [key: number]: boolean;
  }>(() => {
    return _.reduce(
      accountTypes,
      (r, account) => {
        return {...r, [account.id]: false};
      },
      {},
    );
  });
  const [accountName, setAccountName] = useState('');
  const [subAccountName, setSubAccountName] = useState<{[key: number]: string}>(
    {},
  );
  const [accountType, setAccountType] = useState<'income' | 'outcome'>(
    'income',
  );

  const handleAddAccount = useCallback(() => {
    dispatch(saveAccountType({name: accountName, type: accountType}));
    console.log(accountName);
  }, [dispatch, accountName, accountType]);

  const handleAddSubAccount = useCallback(
    (accountId: number) => {
      dispatch(
        saveSubAccountType({
          name: subAccountName[accountId],
          accountTypeId: accountId,
        }),
      );
    },
    [dispatch, subAccountName],
  );
  console.log(_.map(accountTypes, (account) => `account-${account.id}`));

  return (
    <div>
      <Typography variant="h4">계정 과목 편집</Typography>
      <TreeView
        className={classes.root}
        expanded={_.map(accountTypes, (account) => `account-${account.id}`)}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}>
        {_.map(accountTypes, (account) => {
          return (
            <StyledTreeItem
              key={`account-${account.id}`}
              nodeId={`account-${account.id}`}
              label={account.name}>
              {_.map(account.accountSubTypes, (sub) => {
                return (
                  <StyledTreeItem
                    key={`sub-account-${sub.id}`}
                    nodeId={`sub-account-${sub.id}`}
                    label={sub.type ? `${sub.name}-${sub.type}` : sub.name}
                  />
                );
              })}
              <StyledTreeItem
                nodeId={`add-sub-account-${account.id}`}
                label={
                  onSubAccountAdd[account.id] ? (
                    <div className={classes.accountAdd}>
                      <TextField
                        id="standard-basic"
                        className={classes.input}
                        size="small"
                        value={subAccountName[account.id]}
                        onChange={(e) =>
                          setSubAccountName({
                            ...subAccountName,
                            [account.id]: e.target.value,
                          })
                        }
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleAddSubAccount(account.id)}>
                        추가
                      </Button>
                    </div>
                  ) : (
                    '계정 과목 추가'
                  )
                }
                onClick={() =>
                  setOnSubAccountAdd({...onSubAccountAdd, [account.id]: true})
                }></StyledTreeItem>
            </StyledTreeItem>
          );
        })}
        <StyledTreeItem
          nodeId="add-account"
          label={
            onAccountAdd ? (
              <div className={classes.accountAdd}>
                <TextField
                  id="standard-basic"
                  className={classes.input}
                  size="small"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                />
                <Select
                  value={accountType}
                  onChange={(e) =>
                    setAccountType(e.target.value as 'income' | 'outcome')
                  }>
                  <MenuItem value="income">수입</MenuItem>
                  <MenuItem value="outcome">비용</MenuItem>
                </Select>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleAddAccount}>
                  추가
                </Button>
              </div>
            ) : (
              '계정 과목 추가'
            )
          }
          onClick={() => setOnAccountAdd(true)}></StyledTreeItem>
      </TreeView>
    </div>
  );
};
