import React from 'react';
import LoginForm from '~/forms/LoginForm';
import {useDispatch} from 'react-redux';
import {loginRequest} from '~/features/Login/slice';
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      flexGrow: 1,
    },
    body: {
      justifyContent: 'flex-start',
      flex: 1,
    },
    footer: {
      display: 'flex',
      backgroundColor: theme.palette.primary.main,
      height: 64,
      color: theme.palette.primary.contrastText,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  }),
);

const LoginScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const submit = (values: any) => {
    const {email, password} = values;
    dispatch(loginRequest({email, password}));
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            3 S&nbsp;&nbsp;&nbsp;B I S
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.body}>
        <LoginForm onSubmit={submit} />
      </div>

      <div className={classes.footer}>
        Copyright © 2020 3S BIS. All rights reserved.
      </div>
    </div>
  );
};

export default LoginScreen;
