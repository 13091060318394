import moment from 'moment-timezone';
import AccountType from '~/components/AccountType';
import {Detail} from 'react-calendar';

export interface Token {
  token: string | undefined;
}

export interface User {
  email: string | undefined;
  password: string | undefined;
}

export interface Payload {
  id: number;
  is_activated: boolean;
  role: string;
  username: string;
}

export enum STAT_SCALE {
  YEAR,
  MONTH,
  WEEK,
  DAY,
}

export enum STAT_MODE {
  TOTAL,
  EMPLOYEE,
  NURSE,
  CARE,
  PNURSE,
  WELFARE,
}

export enum STAT_JOB_TYPE {
  TOTAL,
  NURSE,
  CARE,
  PNURSE,
  WELFARE,
}

export enum SERVICE_TYPE {
  TOTAL,
  NURSING,
  CARE,
  BATH,
}

export const SCALE_TO_VIEW = {
  [STAT_SCALE.YEAR]: 'decade' as Detail,
  [STAT_SCALE.MONTH]: 'year' as Detail,
  [STAT_SCALE.WEEK]: 'month' as Detail,
  [STAT_SCALE.DAY]: 'month' as Detail,
};

export const SCALE_TO_VIEW_2 = {
  [STAT_SCALE.YEAR]: 'century' as Detail,
  [STAT_SCALE.MONTH]: 'decade' as Detail,
  [STAT_SCALE.WEEK]: 'year' as Detail,
  [STAT_SCALE.DAY]: 'year' as Detail,
};

export interface StatReqeustPayload {
  mode?: STAT_MODE;
  jobType?: STAT_JOB_TYPE;
  scale: STAT_SCALE;
  datetime: moment.Moment;
  serviceType?: SERVICE_TYPE;
}

export interface AccountType {
  id: number;
  name: string;
  type: 'income' | 'outcome';
  code?: string;
  accountSubTypes: AccountSubType[];
}

export type NewAccountType = Omit<AccountType, 'id' | 'accountSubTypes'>;

export interface AccountSubType {
  id: number;
  name: string;
  code?: string;
  comment?: string;
  type?: string;
}

export type NewAccountSubType = Omit<AccountSubType, 'id'> & {
  accountTypeId: number;
};

export interface AccountInput {
  accountId: string;
  accountSubId: number;
  amount: number;
}

export type EmployeeAccountInput = {
  employeeId: string;
} & AccountInput;

export interface Employee {
  id: number;
  name: string;
  birth: string;
  sex?: string;
}

export interface Account {
  id: number;
  date: string;
  memo?: string;
  amount: number;
  accountType?: AccountType;
  accountSubType?: AccountSubType;
  employee?: Employee;
}

export type NewAccount = Omit<Account, 'id'> & {
  accountSubTypeId: number;
  accountTypeId: number;
  employeeId?: number;
};

export const SERVICE_COLORS: {[key: string]: string} = {
  방문간호: '#f7c1bf',
  방문요양: '#cd4142',
  방문목욕: '#ef5927',
  인건비: '#f4efdb',
  사무운영비: '#fad44f',
  업무추진비: '#007845',
  사업운영비: '#c7ddea',
  시설비: '#1a4770',
  사업비: '#714459',
  매출: '#ef5927',
  지출: '#007845',
};

export const WAGE_MINIMUM_RATIO: {[key: string]: number} = {
  방문요양: 0.864,
  방문간호: 0.588,
  방문목욕: 0.491,
  사회복지사: 0,
};

export interface StatResult {
  [key: string]: {[key: string]: number};
}
