import {combineReducers} from '@reduxjs/toolkit';
import {reducer as formReducer} from 'redux-form';
import scheduleStatReducer, {
  SCHEDULES_STAT,
} from '~/features/ScheduleStat/slice';
import dutyStatReducer, {DUTIES_STAT} from '~/features/DutyStat/slice';
import scheduleReducer, {SCHEDULES} from '~/features/Schedule/slice';
import loginReducer, {LOGIN} from '~/features/Login/slice';
import accountTypeReducer, {ACCOUNT_TYPE} from '~/features/AccountType/slice';
import employeeReducer, {EMPLOYEES} from '~/features/Employee/slice';
import accountsReducer, {ACCOUNTS} from '~/features/Account/slice';
import patientsReducer, {PATIENT} from '~/features/Patient/slice';
import bepReducer, {BEP} from '~/features/BEP/slice';

const rootReducer = combineReducers({
  [SCHEDULES_STAT]: scheduleStatReducer,
  [DUTIES_STAT]: dutyStatReducer,
  [SCHEDULES]: scheduleReducer,
  [LOGIN]: loginReducer,
  [ACCOUNT_TYPE]: accountTypeReducer,
  [EMPLOYEES]: employeeReducer,
  [ACCOUNTS]: accountsReducer,
  [PATIENT]: patientsReducer,
  [BEP]: bepReducer,
  form: formReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
