import {call, put, takeLatest} from 'redux-saga/effects';
import {successLoadEmployee, loadEmployee} from './slice';
import * as api from '~/api';

function* handleLoad() {
  try {
    const resp = yield call(api.getEmployees);
    yield put(successLoadEmployee(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchEmployees() {
  yield takeLatest(loadEmployee, handleLoad);
}
