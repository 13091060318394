import React from 'react';
import Calendar, {CalendarProps} from 'react-calendar';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  calendar: {
    width: '100%',
  },
}));

const StyledCalendar = (props: CalendarProps) => {
  const classes = useStyles();
  return <Calendar className={classes.calendar} {...props} />;
};

export default StyledCalendar;
