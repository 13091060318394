import React from 'react';
import {WrappedFieldProps} from 'redux-form';
import {InputHTMLAttributes} from 'react';
import {
  TextField,
  Select,
  MenuItem,
  StandardTextFieldProps,
  TableCell,
} from '@material-ui/core';
import _ from 'lodash';
import DatePicker from 'react-date-picker';
import {Detail} from 'react-calendar';
import {StatResult} from '~/types/types';

const renderTextField = (field: WrappedFieldProps & StandardTextFieldProps) => {
  const {input, type, autoFocus, ...restField} = field;
  return (
    <TextField
      fullWidth
      autoFocus={autoFocus}
      type={type}
      {...input}
      {...restField}
    />
  );
};

const renderSelect = (
  field: WrappedFieldProps &
    InputHTMLAttributes<HTMLInputElement> & {
      items: {id: number; name: string}[];
    },
) => {
  const {input, type, autoFocus, items} = field;
  return (
    <Select
      required
      fullWidth
      autoFocus={autoFocus}
      label={input.name}
      type={type}
      {...input}>
      {_.map(items, (act) => (
        <MenuItem key={`account-${act.id}`} value={act.id}>
          {act.name}
        </MenuItem>
      ))}
    </Select>
  );
};
const renderDatePicker = (
  field: WrappedFieldProps &
    InputHTMLAttributes<HTMLInputElement> & {
      view: Detail;
    },
) => {
  const {input, view} = field;
  return (
    <DatePicker
      name={input.name}
      value={input.value}
      onClickMonth={input.onChange}
      onClickDay={input.onChange}
      view={view}
    />
  );
};

const renderDateTableHeader = (
  viewType: Detail,
  stat: {[key: string]: any},
) => {
  if (viewType === 'decade') {
    return _.range(0, 12).map((m) => <TableCell>{m + 1}월</TableCell>);
  } else if (viewType === 'century') {
    return _.keys(stat).map((m) => <TableCell>{m}년</TableCell>);
  }
};

export {renderTextField, renderDatePicker, renderSelect, renderDateTableHeader};
