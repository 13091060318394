import React from 'react';
import {useSelector} from 'react-redux';
import {SCHEDULES_STAT} from '~/features/ScheduleStat/slice';
import {RootState} from '~/app/rootReducer';
import ReactEcharts from 'echarts-for-react';
import {DUTIES_STAT} from '~/features/DutyStat/slice';
import _ from 'lodash';
import {Total} from '~/models/statistics';
import {STAT_MODE} from '~/types/types';

export enum GRAPH_TYPE {
  ALL,
  PRICE,
  HOURS,
  PPH,
}

interface Props {
  type: GRAPH_TYPE;
  mode: STAT_MODE;
  onClick?: (date: string) => void;
}

const titles = {
  [GRAPH_TYPE.ALL]: '',
  [GRAPH_TYPE.PRICE]: '수가',
  [GRAPH_TYPE.HOURS]: '근무시간',
  [GRAPH_TYPE.PPH]: '시간당 수가',
};
// TODO: change to apex chart
export default ({type, mode, onClick}: Props) => {
  const getTotal = (sch_total: Total[], duty_total: Total[]) => {
    if (type === GRAPH_TYPE.PRICE) {
      return {
        sch: _.map(sch_total, (t) => t.price),
        duties: _.map(duty_total, (t) => t.price),
      };
    } else if (type === GRAPH_TYPE.HOURS) {
      return {
        sch: _.map(sch_total, (t) => t.hours),
        duties: _.map(duty_total, (t) => t.hours),
      };
    } else if (type === GRAPH_TYPE.PPH) {
      return {
        sch: _.map(sch_total, (t) => _.round(t.price / t.hours, 0)),
        duties: _.map(duty_total, (t) => _.round(t.price / t.hours, 0)),
      };
    }
    return {sch: [], duties: []};
  };

  const ranges = useSelector((state: RootState) => {
    const {total, employee, nurse, care} = state[SCHEDULES_STAT];
    if (mode === STAT_MODE.EMPLOYEE) {
      return employee.range;
    } else if (mode === STAT_MODE.NURSE) {
      return nurse.range;
    } else if (mode === STAT_MODE.CARE) {
      return care.range;
    }
    return total.range;
  });
  const total = useSelector((state: RootState) => {
    const {
      total: sch_total,
      employee: sch_employee,
      nurse: sch_nurse,
      care: sch_care,
    } = state[SCHEDULES_STAT];
    const {
      total: duty_total,
      employee: duty_employee,
      nurse: duty_nurse,
      care: duty_care,
    } = state[DUTIES_STAT];

    if (mode === STAT_MODE.EMPLOYEE) {
      return getTotal(sch_employee.total, duty_employee.total);
    } else if (mode === STAT_MODE.NURSE) {
      return getTotal(sch_nurse.total, duty_nurse.total);
    } else if (mode === STAT_MODE.CARE) {
      return getTotal(sch_care.total, duty_care.total);
    }
    return getTotal(sch_total.total, duty_total.total);
  });
  const loading = useSelector(
    (state: RootState) => state[SCHEDULES_STAT].loading,
  );

  return (
    <>
      <ReactEcharts
        showLoading={loading}
        onEvents={{
          click: (params) => {
            if (onClick) {
              onClick(params.name);
            }
          },
        }}
        option={{
          grid: {left: 70},
          title: {
            text: titles[type],
          },
          tooltip: {},
          xAxis: {type: 'category', data: ranges},
          yAxis: {},
          series: [
            {
              type: 'bar',
              data: total.sch,
              encode: {
                x: 'month',
                y: 'price',
              },
            },
            {
              type: 'bar',
              data: total.duties,
              encode: {
                x: 'month',
                y: 'price',
              },
            },
          ],
        }}
      />
    </>
  );
};
