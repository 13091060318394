import React, {useEffect} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {RootState} from './rootReducer';
import {checkLogin, LOGIN} from '~/features/Login/slice';
import LoginScreen from '~/screens/LoginScreen';
import HomeScreen from '~/screens/HomeScreen';
import {CssBaseline} from '@material-ui/core';

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: RootState) => state[LOGIN].authenticated,
  );
  const token = useSelector((state: RootState) => state[LOGIN].token);

  useEffect(() => {
    if (token) {
      // Add a request interceptor
      axios.interceptors.request.use(
        function (config) {
          // Do something before request is sent
          config.headers.Authorization = `Bearer ${token}`;
          config.headers.Accept = 'application/json';
          return config;
        },
        function (error) {
          // Do something with request error
          return Promise.reject(error);
        },
      );
    }
  }, [token]);

  useEffect(() => {
    dispatch(checkLogin());
  }, [dispatch]);

  return (
    <div>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          {isAuthenticated ? (
            <Route path="/" component={HomeScreen} />
          ) : (
            <Route path="/" component={LoginScreen} />
          )}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
