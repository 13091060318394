import React, {useState, useCallback, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import clsx from 'clsx';
import {
  Cell,
  Pie,
  PieChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ReferenceLine,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';
import {loadDutyStat, loadAccStat, BEP} from '~/features/BEP/slice';
import {RootState} from '~/app/rootReducer';
import {loadAccountType, ACCOUNT_TYPE} from '~/features/AccountType/slice';
import {STAT_SCALE, SERVICE_COLORS} from '~/types/types';
import SquareMarker from '~/components/SquareMarker';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {renderDateTableHeader} from '~/utils';

const useStyles = makeStyles((theme) => ({
  date: {
    width: 120,
    padding: theme.spacing(1),
  },
  dateItem: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    textAlign: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  chartContainer: {
    flexGrow: 1,
    height: 500,
  },
  pieContainer: {
    width: 200,
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
}));

const BEPScreen = ({statScale, viewType, date}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const duty = useSelector((state: RootState) => state[BEP].duty);
  const account = useSelector((state: RootState) => state[BEP].account);
  const accountTypes = useSelector(
    (state: RootState) => state[ACCOUNT_TYPE].accountTypes,
  );

  const [data, setData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  const [pieData, setPieData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  const [pieOuterData, setPieOuterData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  const [incomePerData, setIncomePerData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();
  const [outcomePerData, setOutcomePerData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();

  const [selectedDate, setSelectedDate] = useState<string>();

  useEffect(() => {
    //if (statScale === STAT_SCALE.MONTH)
    {
      if (selectedDate) {
        const incomes = ['방문간호', '방문요양', '방문목욕'];
        const outcomes = _.map(accountTypes, (acc) => acc.name);
        const dutyItem = duty[selectedDate];
        const accItem = account[selectedDate];
        if (dutyItem && accItem) {
          const amountData = [
            ..._.map(incomes, (income) => ({
              name: income,
              value: dutyItem[income] ?? 0,
            })),
            ..._.map(outcomes, (outcom) => {
              const value = accItem[outcom] ?? 0;
              return {
                name: outcom,
                value: value > 0 ? -value : value,
              };
            }),
          ];
          setData(amountData);

          setPieData(
            _.filter(amountData, (d) => d.value !== 0).map((d) => ({
              ...d,
              value: d.value > 0 ? d.value : -d.value,
            })),
          );

          const outcomeData = _.filter(amountData, (v) =>
            _.includes(outcomes, v.name),
          );
          const outTotal = _.sumBy(outcomeData, (v) =>
            v.value > 0 ? v.value : -v.value,
          );
          const outcomePerData = _.map(outcomeData, (amount) => ({
            ...amount,
            value: _.round(
              ((amount.value > 0 ? amount.value : -amount.value) / outTotal) *
                100,
              2,
            ),
          }));
          setOutcomePerData(outcomePerData);

          const incomeData = _.filter(amountData, (v) =>
            _.includes(incomes, v.name),
          );
          const inTotal = _.sumBy(incomeData, (v) =>
            v.value > 0 ? v.value : -v.value,
          );
          const incomePerData = _.map(incomeData, (amount) => ({
            ...amount,
            value: _.round(
              ((amount.value > 0 ? amount.value : -amount.value) / inTotal) *
                100,
              2,
            ),
          }));

          setIncomePerData(incomePerData);

          setPieOuterData([
            {name: '매출', value: _.sumBy(incomeData, 'value')},
            {name: '지출', value: -_.sumBy(outcomeData, 'value')},
          ]);
        }
      }
    }
  }, [duty, accountTypes, account, selectedDate]);

  useEffect(() => {
    const datetime = moment(date);
    if (statScale === STAT_SCALE.MONTH) {
      datetime.month(6);
      setSelectedDate(moment(datetime).startOf('year').format('YYYY-MM'));
    }
    const request = {
      scale: statScale,
      datetime,
    };
    dispatch(loadDutyStat(request));
    dispatch(loadAccStat(request));
    dispatch(loadAccountType());
  }, [dispatch, date, statScale]);

  const renderDateSelector = useCallback(() => {
    if (viewType === 'decade') {
      return _.range(0, 12).map((m) => {
        const datetime = moment(date).month(m).format('YYYY-MM');
        return (
          <Grid item xs={6} key={`date-selector-${m}`}>
            <div
              className={clsx(
                classes.dateItem,
                datetime === selectedDate && classes.selected,
              )}
              onClick={() => setSelectedDate(datetime)}>
              {m + 1}월
            </div>
          </Grid>
        );
      });
    } else if (viewType === 'century') {
      return _.keys(duty).map((m) => {
        const datetime = moment(date).year(parseInt(m)).format('YYYY');
        return (
          <Grid item xs={6} key={`date-selector-${m}`}>
            <div
              className={clsx(
                classes.dateItem,
                datetime === selectedDate && classes.selected,
              )}
              onClick={() => setSelectedDate(datetime)}>
              {m}년
            </div>
          </Grid>
        );
      });
    }
  }, [date, classes, viewType, selectedDate, duty]);

  const renderTableHeader = useCallback(() => {
    return renderDateTableHeader(viewType, duty);
  }, [viewType, duty]);

  const renderLabel = (entry: any) => {
    return entry.name;
  };

  return (
    <>
      <Paper>
        <div className={classes.main}>
          <div className={classes.date}>
            <Grid container spacing={1}>
              {renderDateSelector()}
            </Grid>
          </div>
          <div className={classes.chartContainer}>
            <ResponsiveContainer>
              <ComposedChart
                layout="vertical"
                data={data}
                margin={{
                  top: 5,
                  right: 0,
                  left: 30,
                  bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" />
                <Tooltip />
                <ReferenceLine x={0} stroke="#000" />
                <Bar dataKey="value" barSize={20} fill="#8884d8">
                  {_.map(data, (entry, index) => {
                    const name: string = entry.name as string;
                    const color = _.has(SERVICE_COLORS, name)
                      ? SERVICE_COLORS[name]
                      : '#000';
                    return <Cell key={`cell-${index}`} fill={color} />;
                  })}
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <div className={classes.pieContainer}>
            <PieChart width={200} height={250}>
              <Pie
                data={pieData}
                cx={100}
                cy={125}
                outerRadius={60}
                paddingAngle={0.5}
                dataKey="value">
                {_.map(pieData, (entry, index) => {
                  const name: string = entry.name as string;
                  const color = _.has(SERVICE_COLORS, name)
                    ? SERVICE_COLORS[name]
                    : '#000';
                  return <Cell key={`cell-${index}`} fill={color} />;
                })}
              </Pie>
              <Pie
                data={pieOuterData}
                cx={100}
                cy={125}
                innerRadius={65}
                outerRadius={80}
                paddingAngle={0.5}
                dataKey="value"
                label={renderLabel}>
                {_.map(pieOuterData, (entry, index) => {
                  const name: string = entry.name as string;
                  const color = _.has(SERVICE_COLORS, name)
                    ? SERVICE_COLORS[name]
                    : '#000';
                  return <Cell key={`cell-outer-${index}`} fill={color} />;
                })}
              </Pie>
            </PieChart>

            <List component="nav" aria-label="secondary mailbox folders">
              {_.map(incomePerData, (d) => (
                <ListItem dense>
                  <SquareMarker color={SERVICE_COLORS[d.name]} />{' '}
                  <ListItemText primary={`${d.name} (${d.value}%)`} />
                </ListItem>
              ))}
              {_.map(outcomePerData, (d) => (
                <ListItem dense>
                  <SquareMarker color={SERVICE_COLORS[d.name]} />{' '}
                  <ListItemText primary={`${d.name} (${d.value}%)`} />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </Paper>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>구분</TableCell>
              {renderTableHeader()}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell rowSpan={4}>매출</TableCell>
              <TableCell>간호</TableCell>
              {_.values(duty).map((d) => (
                <TableCell align="right">
                  {(_.has(d, '방문간호')
                    ? _.get(d, '방문간호')
                    : 0
                  ).toLocaleString()}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>요양</TableCell>
              {_.values(duty).map((d) => (
                <TableCell align="right">
                  {(_.has(d, '방문요양')
                    ? _.get(d, '방문요양')
                    : 0
                  ).toLocaleString()}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>목욕</TableCell>
              {_.values(duty).map((d) => (
                <TableCell align="right">
                  {(_.has(d, '방문목욕')
                    ? _.get(d, '방문목욕')
                    : 0
                  ).toLocaleString()}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>소계</TableCell>
              {_.values(duty).map((d) => (
                <TableCell align="right">
                  {_.sum(_.values(d)).toLocaleString()}
                </TableCell>
              ))}
            </TableRow>

            <TableRow>
              <TableCell rowSpan={accountTypes.length + 2}>지출</TableCell>
            </TableRow>

            {_.map(accountTypes, (acc) => (
              <TableRow>
                <TableCell>{acc.name}</TableCell>
                {_.values(account).map((d) => (
                  <TableCell align="right">
                    {(_.has(d, acc.name)
                      ? _.get(d, acc.name)
                      : 0
                    ).toLocaleString()}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell>소계</TableCell>
              {_.values(account).map((d) => (
                <TableCell>{_.sum(_.values(d)).toLocaleString()}</TableCell>
              ))}
            </TableRow>

            <TableRow>
              <TableCell colSpan={2}>손익</TableCell>

              {_.zip(_.values(duty), _.values(account)).map((d) => (
                <TableCell align="right">
                  {(
                    _.sum(_.values(d[0])) - _.sum(_.values(d[1]))
                  ).toLocaleString()}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(BEPScreen);
