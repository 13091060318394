import React from 'react';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';
import {GRAPH_TYPE} from './Graph';

interface Props {
  value: GRAPH_TYPE;
  onChange: (mode: GRAPH_TYPE) => void;
}

const GraphSelector = ({value, onChange}: Props) => {
  return (
    <div>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        onChange={(e) => onChange(parseInt(e.target.value) as GRAPH_TYPE)}
        defaultValue={0}
        value={value}>
        <FormControlLabel
          value={GRAPH_TYPE.ALL}
          control={<Radio color="primary" />}
          label="전체"
          labelPlacement="end"
        />
        <FormControlLabel
          value={GRAPH_TYPE.PRICE}
          control={<Radio color="primary" />}
          label="수가"
          labelPlacement="end"
        />
        <FormControlLabel
          value={GRAPH_TYPE.HOURS}
          control={<Radio color="primary" />}
          label="근무시간"
          labelPlacement="end"
        />
        <FormControlLabel
          value={GRAPH_TYPE.PPH}
          control={<Radio color="primary" />}
          label="시간당수가"
          labelPlacement="end"
        />
      </RadioGroup>
    </div>
  );
};

export default GraphSelector;
