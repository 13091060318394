import {AccountType, NewAccountType, NewAccountSubType} from '~/types/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type State = {
  loading: boolean;
  accountTypes: AccountType[];
};

let initialState: State = {
  loading: false,
  accountTypes: [],
};

const slice = createSlice({
  name: 'account_type',
  initialState,
  reducers: {
    loadAccountType: (state) => {
      state.loading = true;
    },
    successAccountType: (state, action: PayloadAction<AccountType[]>) => {
      state.loading = false;
      state.accountTypes = action.payload;
    },
    saveAccountType: (state, _action: PayloadAction<NewAccountType>) => {
      state.loading = true;
    },
    saveSubAccountType: (state, _action: PayloadAction<NewAccountSubType>) => {
      state.loading = true;
    },
  },
});

export const {
  loadAccountType,
  successAccountType,
  saveAccountType,
  saveSubAccountType,
} = slice.actions;
export const ACCOUNT_TYPE = slice.name;
export default slice.reducer;
