import React, {useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';

import {RootState} from '~/app/rootReducer';
import {ACCOUNT_TYPE, loadAccountType} from '~/features/AccountType/slice';
import {loadEmployee, EMPLOYEES} from '~/features/Employee/slice';
import {saveAccount, loadAccounts, ACCOUNTS} from '~/features/Account/slice';
import AccountInputForm from '~/forms/AccountInputForm';

import {NewAccount} from '~/types/types';
import AccountTable from '~/components/AccountTable';

const HumanResourceScreen = () => {
  const dispatch = useDispatch();
  const accountTypes =
    useSelector((state: RootState) =>
      _.filter(
        state[ACCOUNT_TYPE].accountTypes,
        (act) => act.name === '인건비',
      ),
    ) ?? [];
  const employees = useSelector(
    (state: RootState) => state[EMPLOYEES].employees,
  );
  const accounts = useSelector((state: RootState) => state[ACCOUNTS].accounts);

  useEffect(() => {
    dispatch(loadAccountType());
    dispatch(loadEmployee());
    dispatch(loadAccounts());
  }, [dispatch]);

  const handleSubmit = useCallback(
    (value: NewAccount) => {
      dispatch(
        saveAccount({
          ...value,
          amount: value.amount as number,
          date: moment(value.date).format(),
        }),
      );
    },
    [dispatch],
  );
  return (
    <div>
      <AccountInputForm
        accounts={accountTypes}
        employees={employees}
        initialValues={{
          accountTypeId: accountTypes[0]?.id,
        }}
        onSubmit={handleSubmit}
      />
      <br />
      <AccountTable accounts={accounts} />
    </div>
  );
};

export default HumanResourceScreen;
