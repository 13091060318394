import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import PeriodSelector from '~/components/PeriodSelector';
import {STAT_SCALE, SCALE_TO_VIEW_2, SERVICE_COLORS} from '~/types/types';
import {Detail} from 'react-calendar';
import moment from 'moment';
import {loadAccStat, BEP} from '~/features/BEP/slice';
import {loadAccountType, ACCOUNT_TYPE} from '~/features/AccountType/slice';
import _ from 'lodash';
import {RootState} from '~/app/rootReducer';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
} from 'recharts';
import StyledCalendar from '~/components/StyledCalendar';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {renderDateTableHeader} from '~/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  chartContainer: {
    width: '100%',
    height: 300,
  },
}));

const OperationScreen = ({statScale, viewType, date}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const account = useSelector((state: RootState) => state[BEP].account);
  const accountTypes = useSelector(
    (state: RootState) => state[ACCOUNT_TYPE].accountTypes,
  );

  const [data, setData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();

  useEffect(() => {
    const r: {name: string}[] = [];
    setData(
      _.transform(
        account,
        (r, v, k) => {
          const datetime = moment(k);
          if (viewType === 'decade') {
            r.push({name: `${datetime.month() + 1}월`, ...v});
          } else if (viewType === 'century') {
            r.push({name: `${datetime.year()}년`, ...v});
          }
        },
        r,
      ),
    );
  }, [account, accountTypes]);

  useEffect(() => {
    const datetime = moment(date);
    if (statScale === STAT_SCALE.MONTH) {
      datetime.month(6);
    }
    const request = {
      scale: statScale,
      datetime,
    };
    dispatch(loadAccStat(request));
    dispatch(loadAccountType());
  }, [dispatch, date, statScale]);

  const renderTableHeader = useCallback(() => {
    return renderDateTableHeader(viewType, account);
  }, [viewType, account]);

  return (
    <>
      <Paper>
        <div className={classes.chartContainer}>
          <ResponsiveContainer>
            <BarChart
              data={data}
              margin={{top: 20, right: 20, left: 20, bottom: 5}}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend height={36} />
              {_.map(accountTypes, (acc) => {
                return (
                  <Bar
                    key={acc.name}
                    dataKey={acc.name}
                    stackId="a"
                    fill={SERVICE_COLORS[acc.name]}
                    name={acc.name}
                  />
                );
              })}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Paper>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>구분</TableCell>
              {renderTableHeader()}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(accountTypes, (acc) => (
              <TableRow>
                <TableCell>{acc.name}</TableCell>
                {_.values(account).map((d) => (
                  <TableCell align="right">
                    {(_.has(d, acc.name)
                      ? _.get(d, acc.name)
                      : 0
                    ).toLocaleString()}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell>소계</TableCell>
              {_.values(account).map((d) => (
                <TableCell align="right">
                  {_.sum(_.values(d)).toLocaleString()}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(OperationScreen);
