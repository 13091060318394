import {call, put, takeLatest, select} from 'redux-saga/effects';
import {
  SCHEDULES_STAT,
  loadSchStatTotal,
  successSchStatTotal,
  loadSchStatEmployee,
  successSchStatEmployee,
  loadSchStatNurse,
  loadSchStatCare,
  successSchStatNurse,
  successSchStatCare,
} from './slice';
import {getScheduleStat} from '~/api';
import {PayloadAction} from '@reduxjs/toolkit';
import {StatReqeustPayload} from '~/types/types';

function* handleStatTotal() {
  try {
    const state = yield select((state) => state[SCHEDULES_STAT]);
    const resp = yield call(getScheduleStat, state.request);
    yield put(successSchStatTotal(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleStatEmployee({payload}: PayloadAction<StatReqeustPayload>) {
  try {
    const resp = yield call(getScheduleStat, payload);
    yield put(successSchStatEmployee(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleStatNurse() {
  try {
    const state = yield select((state) => state[SCHEDULES_STAT]);
    const resp = yield call(getScheduleStat, state.request);
    yield put(successSchStatNurse(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleStatCare() {
  try {
    const state = yield select((state) => state[SCHEDULES_STAT]);
    const resp = yield call(getScheduleStat, state.request);
    yield put(successSchStatCare(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchScheduleStat() {
  yield takeLatest(loadSchStatTotal, handleStatTotal);
  yield takeLatest(loadSchStatEmployee, handleStatEmployee);
  yield takeLatest(loadSchStatNurse, handleStatNurse);
  yield takeLatest(loadSchStatCare, handleStatCare);
}
