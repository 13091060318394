import React, {useState, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducer';
import {STAT_SCALE} from '~/types/types';
import Calendar, {Detail} from 'react-calendar';
import {
  makeStyles,
  Paper,
  TextField,
  Button,
  Typography,
} from '@material-ui/core';
import {
  Calendar as BigCalendar,
  momentLocalizer,
  Event,
  EventProps,
} from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/sass/styles.scss';
import {SCHEDULES, loadSchedules} from '~/features/Schedule/slice';
import _ from 'lodash';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  calendarPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    minHeight: 700,
    alignItems: 'center',
  },
  calendar: {
    width: 500,
  },
  bigCalendar: {
    width: '100%',
    flex: 1,
  },
  filterTitle: {
    fontSize: '1rem',
    borderRightWidth: 3,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  filterContent: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  chartContainer: {
    width: '100%',
    height: '30vh',
  },
  flex1: {
    flex: 1,
    marginLeft: 10,
  },
}));

type MyEvent = Event & {
  employee?: string;
  patient?: string;
};

const AchievementScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const schedules = useSelector(
    (state: RootState) => state[SCHEDULES].schedules,
  );

  const [sEvents, setSEvents] = useState<Event[]>([]);
  const [dEvents, setDEvents] = useState<Event[]>([]);

  const [statScale] = useState<STAT_SCALE>(STAT_SCALE.MONTH);
  const [viewType] = useState<Detail>('year');
  const [date, setDate] = useState(new Date());
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    dispatch(
      loadSchedules({
        scale: statScale,
        date: moment(date),
      }),
    );
  }, [statScale, date]);

  const handleSearch = useCallback(() => {
    setSEvents(
      _.chain(schedules)
        .filter((s) => {
          if (_.startsWith(search, 'L')) {
            const patient = s.patient;
            if (patient === undefined) {
              return false;
            }
            return patient.code.indexOf(search) >= 0;
          } else {
            const employee = s.employee;
            if (employee === undefined) {
              return false;
            }
            return employee.name.indexOf(search) >= 0;
          }
        })
        .map<MyEvent>((s, i) => {
          const start = moment(s.startDate);
          const end = moment(s.endDate);
          return {
            id: i,
            title: `${start.format('HH:mm')}~${end.format('HH:mm')}`,
            employee: s.employee?.name,
            patient: s.patient?.name,
            start: start.toDate(),
            end: end.toDate(),
          };
        })
        .value(),
    );

    setDEvents(
      _.chain(schedules)
        .filter((s) => {
          if (_.startsWith(search, 'L')) {
            const patient = s.patient;
            if (patient === undefined) {
              return false;
            }
            return patient.code.indexOf(search) >= 0;
          } else {
            const employee = s.employee;
            if (employee === undefined) {
              return false;
            }
            return employee.name.indexOf(search) >= 0;
          }
        })
        .map<MyEvent | undefined>((s, i) => {
          if (s.duty) {
            const start = moment(s.duty.startDate);
            const end = moment(s.duty.endDate);
            return {
              id: i,
              title: `${start.format('HH:mm')}~${end.format('HH:mm')}`,
              employee: s.employee?.name,
              patient: s.patient?.name,
              start: start.toDate(),
              end: end.toDate(),
            };
          }
        })
        .compact()
        .value(),
    );
  }, [schedules, search]);

  const handleDate = useCallback((date: Date) => {
    setDate(date);
  }, []);

  const EventComp: React.ComponentType<EventProps<MyEvent>> = ({event}) => {
    return (
      <div>
        <div>
          {event.title} {event.employee}/{event.patient}
        </div>
      </div>
    );
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div>
          <Calendar
            className={classes.calendar}
            value={date}
            onClickYear={handleDate}
            onClickMonth={handleDate}
            onClickDay={handleDate}
            view={viewType}
          />
        </div>
        <div className={classes.flex1}>
          <TextField
            label="직원이름 또는 인정번호"
            fullWidth
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button variant="outlined" color="primary" onClick={handleSearch}>
            검색
          </Button>
        </div>
      </Paper>
      <br />
      <Paper className={classes.calendarPaper}>
        <Typography variant="h4" gutterBottom>
          {date.getMonth() + 1}월 계획 스케쥴
        </Typography>
        <BigCalendar
          popup
          className={classes.bigCalendar}
          localizer={localizer}
          date={date}
          events={sEvents}
          views={{month: true}}
          defaultView={'month'}
          startAccessor="start"
          endAccessor="end"
          toolbar={false}
          components={{event: EventComp}}
        />
      </Paper>
      <br />
      <Paper className={classes.calendarPaper}>
        <Typography variant="h4" gutterBottom>
          {date.getMonth() + 1}월 완료 스케쥴
        </Typography>
        <BigCalendar
          popup
          className={classes.bigCalendar}
          localizer={localizer}
          date={date}
          events={dEvents}
          views={{month: true}}
          defaultView={'month'}
          startAccessor="start"
          endAccessor="end"
          toolbar={false}
          components={{event: EventComp}}
        />
      </Paper>
    </>
  );
};

export default AchievementScreen;
