import React, {useEffect} from 'react';
import AccountType from '~/components/AccountType';
import {useDispatch, useSelector} from 'react-redux';
import {loadAccountType, ACCOUNT_TYPE} from '~/features/AccountType/slice';
import {RootState} from '~/app/rootReducer';

const SettingsScreen = () => {
  const dispatch = useDispatch();
  const accountTypes = useSelector(
    (state: RootState) => state[ACCOUNT_TYPE].accountTypes,
  );

  useEffect(() => {
    dispatch(loadAccountType());
  }, [dispatch]);

  return (
    <div>
      <AccountType accountTypes={accountTypes} />
    </div>
  );
};

export default SettingsScreen;
