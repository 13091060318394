import React, {useState} from 'react';
import {InjectedFormProps, Field, reduxForm} from 'redux-form';
import {Typography, Grid, makeStyles, Button} from '@material-ui/core';
import _ from 'lodash';

import {AccountType, NewAccount} from '~/types/types';
import {renderSelect, renderDatePicker, renderTextField} from '~/utils';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  grid: {
    alignItems: 'flex-end',
  },
}));

type Props = {
  accounts?: AccountType[];
};

const EtcInputForm = (props: Props & InjectedFormProps<NewAccount>) => {
  const classes = useStyles();
  const {accounts, handleSubmit} = props;
  const [selectedAccount, setSelectedAccount] = useState(-1);

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        계정과목 선택
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          계정:
          <Field
            name="accountTypeId"
            component={renderSelect}
            items={accounts}
            onChange={(e: any) => {
              setSelectedAccount(
                _.findIndex(accounts, (act) => act.id === e.target.value),
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {selectedAccount >= 0 && accounts && (
            <>
              세부계정:
              <Field
                name="accountSubTypeId"
                component={renderSelect}
                items={accounts[selectedAccount].accountSubTypes}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12} sm={6}>
          <Field
            name="amount"
            label="금액"
            component={renderTextField}
            type="number"
            parse={(value: any) => Number(value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="memo"
            label="메모"
            component={renderTextField}
            type="string"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12} sm={6}>
          날짜: <Field name="date" view="month" component={renderDatePicker} />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}>
        추가
      </Button>
    </form>
  );
};

export default reduxForm<NewAccount, Props>({
  form: 'ectInput',
})(EtcInputForm);
