import React, {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadAccountType, ACCOUNT_TYPE} from '~/features/AccountType/slice';
import {RootState} from '~/app/rootReducer';
import _ from 'lodash';
import EtcInputForm from '~/forms/EtcInputForm';
import {NewAccount} from '~/types/types';
import {saveAccount} from '~/features/Account/slice';
import moment from 'moment';

const ETCInputScreen = () => {
  const dispatch = useDispatch();
  const accountTypes = useSelector((state: RootState) =>
    _.filter(state[ACCOUNT_TYPE].accountTypes, (act) => act.name !== '인건비'),
  );

  useEffect(() => {
    dispatch(loadAccountType());
  }, [dispatch]);

  const handleSubmit = useCallback(
    (value: NewAccount) => {
      dispatch(
        saveAccount({
          ...value,
          amount: value.amount as number,
          date: moment(value.date).format(),
        }),
      );
    },
    [dispatch],
  );

  return (
    <div>
      <EtcInputForm accounts={accountTypes} onSubmit={handleSubmit} />
    </div>
  );
};

export default ETCInputScreen;
