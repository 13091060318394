import React, {useState, useEffect, useRef} from 'react';
import {
  AppBar,
  makeStyles,
  Toolbar,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import HomeRouter from '~/routers/HomeRouter';
import _ from 'lodash';
import {useHistory, useLocation} from 'react-router-dom';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: 36,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    marginLeft: drawerWidth,
  },
  paper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuTitle: {
    ...theme.typography.h6,
  },
}));

const HomeScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const categories = useRef([
    {
      id: '센터 운영 정보',
      children: [
        {
          id: 'BEP 확인',
          linkto: '/',
        },
        {id: '인건비', linkto: '/wage'},
        {id: '운영관리비', linkto: '/operation'},
      ],
    },
    {
      id: '직원별 업무 현황',
      children: [
        {id: '종합 업무 퍼포먼스', linkto: '/performance'},
        {id: '계획 달성률', linkto: '/achievement'},
        {id: '업무 스케쥴', linkto: '/schedule'},
      ],
    },
    {
      id: '수급자 현황',
      children: [
        {id: '수급자 현황', linkto: '/pstat'},
        {id: '서비스 시간', linkto: '/ptime'},
        {id: '처치 히스토리', linkto: '/history'},
      ],
    },
    {
      id: '재고현황',
      children: [
        {id: '현재 재고 현황', linkto: '/stock'},
        {id: '직원별 분출 통계', linkto: '/distribution'},
        {id: '재고 알림 설정', linkto: '/stock-alarm'},
      ],
    },
    {
      id: '운영 성과',
      children: [
        {id: '성과관리', linkto: '/perf-amd'},
        {id: '원가•목표 관리', linkto: '/cost'},
      ],
    },
    {
      id: '입력',
      children: [
        {id: '파일 업로드', linkto: '/insurance'},
        {id: '기타 입력', linkto: '/etc-input'},
      ],
    },
  ]).current;
  const [activates, setActivates] = useState<{[key: string]: boolean}>({});

  useEffect(() => {
    const cat = _.chain(categories)
      .reduce((r, c) => {
        const sub = _.reduce(
          c.children,
          (ret, ch) => {
            return {...ret, [ch.id]: ch.linkto === location.pathname};
          },
          {},
        );
        return {...r, ...sub};
      }, {})
      .value();
    setActivates(cat);
  }, [categories, location]);

  const handleListItemClick = (childId: string, linkto: string) => {
    history.push(linkto);
    setActivates(_.mapValues(activates, (_val, key) => key === childId));
  };

  const gotoSettings = () => {
    history.push('/settings');
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title} variant="h6">
            3 S&nbsp;&nbsp;&nbsp;B I S
          </Typography>

          <Button
            color="inherit"
            endIcon={<SettingsIcon />}
            onClick={handleClick}>
            설정
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem onClick={gotoSettings}>계정과목 등록•편집</MenuItem>
            <MenuItem onClick={gotoSettings}>간호비품 등록•편집</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.toolbar}></div>
        <Divider />
        <List>
          {categories.map(({id, children}) => (
            <React.Fragment key={id}>
              <ListItem>
                <ListItemText
                  classes={{primary: classes.menuTitle}}
                  primary={id}
                />
              </ListItem>
              {children.map(({id: childId, linkto}) => (
                <ListItem
                  key={childId}
                  button
                  selected={activates[childId]}
                  onClick={() => handleListItemClick(childId, linkto)}>
                  <ListItemText primary={childId} />
                </ListItem>
              ))}
              <Divider className={classes.divider} />
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.paper}>
          <HomeRouter />
        </div>
      </main>
    </div>
  );
};

export default HomeScreen;
