import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Statistics from '~/models/statistics';
import {STAT_SCALE, StatReqeustPayload} from '~/types/types';

type State = {
  loading: boolean;
  request: StatReqeustPayload | undefined;
  total: Statistics;
  employee: Statistics;
  nurse: Statistics;
  care: Statistics;
};

let initialState: State = {
  loading: false,
  request: undefined,
  total: {range: [], total: [], raw: []},
  employee: {range: [], total: [], raw: []},
  nurse: {range: [], total: [], raw: []},
  care: {range: [], total: [], raw: []},
};

const slice = createSlice({
  name: 'duties_stat',
  initialState,
  reducers: {
    loadDutyStatTotal: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successDutyStatTotal: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.total = action.payload;
    },
    loadDutyStatEmployee: (
      state,
      action: PayloadAction<StatReqeustPayload>,
    ) => {
      state.loading = true;
      state.request = action.payload;
    },
    successDutyStatEmployee: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.employee = action.payload;
    },
    loadDutyStatNurse: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successDutyStatNurse: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.nurse = action.payload;
    },
    loadDutyStatCare: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successDutyStatCare: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.care = action.payload;
    },
  },
});

export const {
  loadDutyStatTotal,
  successDutyStatTotal,
  loadDutyStatEmployee,
  successDutyStatEmployee,
  loadDutyStatNurse,
  successDutyStatNurse,
  loadDutyStatCare,
  successDutyStatCare,
} = slice.actions;
export const DUTIES_STAT = slice.name;
export default slice.reducer;
