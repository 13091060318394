import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Statistics from '~/models/statistics';
import {StatReqeustPayload} from '~/types/types';

type State = {
  loading: boolean;
  request: StatReqeustPayload | undefined;
  total: Statistics;
  employee: Statistics;
  nurse: Statistics;
  care: Statistics;
};

let initialState: State = {
  loading: false,
  request: undefined,
  total: {range: [], total: [], raw: []},
  employee: {range: [], total: [], raw: []},
  nurse: {range: [], total: [], raw: []},
  care: {range: [], total: [], raw: []},
};

const slice = createSlice({
  name: 'schedules_stat',
  initialState,
  reducers: {
    loadSchStatTotal: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successSchStatTotal: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.total = action.payload;
    },
    loadSchStatEmployee: (
      state,
      _action: PayloadAction<StatReqeustPayload>,
    ) => {
      state.loading = true;
    },
    successSchStatEmployee: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.employee = action.payload;
    },
    loadSchStatNurse: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successSchStatNurse: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.nurse = action.payload;
    },
    loadSchStatCare: (state, action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
      state.request = action.payload;
    },
    successSchStatCare: (state, action: PayloadAction<Statistics>) => {
      state.loading = false;
      state.care = action.payload;
    },
  },
});

export const {
  loadSchStatTotal,
  successSchStatTotal,
  loadSchStatEmployee,
  successSchStatEmployee,
  loadSchStatNurse,
  successSchStatNurse,
  loadSchStatCare,
  successSchStatCare,
} = slice.actions;
export const SCHEDULES_STAT = slice.name;
export default slice.reducer;
