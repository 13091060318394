import React from 'react';
import {STAT_MODE, STAT_JOB_TYPE} from '~/types/types';
import {RadioGroup, FormControlLabel, Radio} from '@material-ui/core';

interface Props {
  value: STAT_JOB_TYPE;
  onChange: (mode: STAT_JOB_TYPE) => void;
}

const JobSelector = ({value, onChange}: Props) => {
  return (
    <div>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        onChange={(e) => onChange(parseInt(e.target.value) as STAT_JOB_TYPE)}
        defaultValue={0}
        value={value}>
        <FormControlLabel
          value={STAT_JOB_TYPE.TOTAL}
          control={<Radio color="primary" />}
          label="전체"
          labelPlacement="end"
        />
        <FormControlLabel
          value={STAT_JOB_TYPE.NURSE}
          control={<Radio color="primary" />}
          label="간호사"
          labelPlacement="end"
        />
        <FormControlLabel
          value={STAT_JOB_TYPE.CARE}
          control={<Radio color="primary" />}
          label="요양보호사"
          labelPlacement="end"
        />
        <FormControlLabel
          value={STAT_JOB_TYPE.PNURSE}
          control={<Radio color="primary" />}
          label="간호조무사"
          labelPlacement="end"
        />
        <FormControlLabel
          value={STAT_JOB_TYPE.WELFARE}
          control={<Radio color="primary" />}
          label="사회복지사"
          labelPlacement="end"
        />
      </RadioGroup>
    </div>
  );
};

export default JobSelector;
