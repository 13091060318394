import React, {useState, useCallback, useEffect} from 'react';
import {
  Paper,
  makeStyles,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {STAT_SCALE, SERVICE_TYPE, WAGE_MINIMUM_RATIO} from '~/types/types';
import {RootState} from '~/app/rootReducer';
import {BEP, loadDutyStat, loadWageStat} from '~/features/BEP/slice';
import moment from 'moment';
import _ from 'lodash';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
  Line,
} from 'recharts';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {renderDateTableHeader} from '~/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  chartContainer: {
    width: '100%',
    height: 300,
  },
}));

const WageScreen = ({statScale, viewType, date, service}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const duty = useSelector((state: RootState) => state[BEP].duty);
  const wages = useSelector((state: RootState) => state[BEP].wage);

  const [data, setData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();

  useEffect(() => {
    setData(
      _.keys(duty).map((k) => {
        let type = '방문간호';
        if (service === SERVICE_TYPE.CARE) {
          type = '방문요양';
        } else if (service === SERVICE_TYPE.BATH) {
          type = '방문목욕';
        }
        const datetime = moment(k);
        let ret: {[key: string]: string | number} = {
          name: `${datetime.month() + 1}월`,
        };
        if (duty[k]) {
          ret = {...ret, 공단수가: duty[k][type] ?? 0};
        }
        if (wages[k]) {
          ret = {...ret, 지급총액: wages[k][type] ?? 0};
        }
        if (duty[k] && wages[k]) {
          const minimumCost = duty[k][type] ?? 0 * WAGE_MINIMUM_RATIO[type];
          const wageCost = wages[k][type] ?? 0;
          if (minimumCost !== 0) {
            ret = {
              ...ret,
              지급비율: _.round((wageCost / minimumCost) * 100, 2),
              권고비율: WAGE_MINIMUM_RATIO[type] * 100,
            };
          }
        }
        return ret;
      }),
    );
  }, [service, duty, wages]);

  useEffect(() => {
    const datetime = moment(date);
    if (statScale === STAT_SCALE.MONTH) {
      datetime.month(6);
    }
    const request = {
      scale: statScale,
      datetime,
    };
    dispatch(loadDutyStat(request));
    dispatch(loadWageStat(request));
  }, [dispatch, date, statScale]);

  const renderTableHeader = useCallback(() => {
    return renderDateTableHeader(viewType, wages);
  }, [viewType, wages]);

  const renderPaymentRatio = useCallback(
    (type: string) => {
      return (
        <>
          <TableRow>
            <TableCell align="center" rowSpan={5}>
              {type}
              <br />
              {(WAGE_MINIMUM_RATIO[type] ?? 0) * 100}
            </TableCell>
            <TableCell>공단수가</TableCell>
            {_.values(duty).map((d) => (
              <TableCell align="right">
                {(_.has(d, type) ? _.get(d, type) : 0).toLocaleString()}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>지급총액</TableCell>
            {_.values(wages).map((d) => (
              <TableCell align="right">
                {(_.has(d, type) ? _.get(d, type) : 0).toLocaleString()}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>권고총액</TableCell>
            {_.zip(_.values(duty), _.values(wages)).map((d) => {
              const minimumCost = _.round(
                ((d[0] || {[type]: 0})[type] ?? 0) * WAGE_MINIMUM_RATIO[type],
              );
              return (
                <TableCell align="right">
                  {(minimumCost === 0 ? '-' : minimumCost).toLocaleString()}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell>과부족</TableCell>
            {_.zip(_.values(duty), _.values(wages)).map((d) => {
              const minimumCost = _.round(
                ((d[0] || {[type]: 0})[type] ?? 0) * WAGE_MINIMUM_RATIO[type],
              );
              const wageCost = (d[1] || {[type]: 0})[type] ?? 0;
              return (
                <TableCell align="right">
                  {(minimumCost === 0
                    ? '-'
                    : wageCost - minimumCost
                  ).toLocaleString()}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell>지급비율</TableCell>
            {_.zip(_.values(duty), _.values(wages)).map((d) => {
              const dutyCost = (d[0] || {[type]: 0})[type] ?? 0;
              const wageCost = (d[1] || {[type]: 0})[type] ?? 0;
              return (
                <TableCell align="right">
                  {(dutyCost === 0
                    ? '-'
                    : (wageCost / dutyCost) * 100
                  ).toLocaleString()}
                </TableCell>
              );
            })}
          </TableRow>
        </>
      );
    },
    [duty, wages],
  );

  return (
    <>
      <Paper>
        <div className={classes.chartContainer}>
          <ResponsiveContainer>
            <ComposedChart
              data={data}
              margin={{top: 20, right: 20, left: 20, bottom: 5}}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" domain={[0, 100]} />
              <Tooltip />
              <Legend />
              <Bar
                yAxisId="left"
                dataKey="공단수가"
                fill="#4472c4"
                name="공단수가"
              />
              <Bar
                yAxisId="left"
                dataKey="지급총액"
                fill="#ed7d31"
                name="지급총액"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="지급비율"
                stroke="#fad44f"
                strokeWidth={2}
                activeDot={{r: 6}}
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="권고비율"
                stroke="#cd4142"
                strokeWidth={2}
                dot={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Paper>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>구분</TableCell>
              {renderTableHeader()}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderPaymentRatio('방문간호')}
            {renderPaymentRatio('방문요양')}
            {renderPaymentRatio('방문목욕')}
            {renderPaymentRatio('사회복지사')}
            <TableRow>
              <TableCell colSpan={2}>인건비</TableCell>
              {_.values(wages).map((d) => (
                <TableCell align="right">
                  {_.sum(_.values(d)).toLocaleString()}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(WageScreen, {service: true});
