import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {StatReqeustPayload, StatResult} from '~/types/types';

interface State {
  loading: boolean;
  duty: StatResult;
  account: StatResult;
  wage: StatResult;
}

const initialState: State = {
  loading: false,
  duty: {},
  account: {},
  wage: {},
};

const slice = createSlice({
  name: 'bep',
  initialState,
  reducers: {
    loadDutyStat: (state, _action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
    },
    successLoadDutyStat: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.duty = action.payload;
    },
    loadAccStat: (state, _action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
    },
    successLoadAccStat: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.account = action.payload;
    },
    loadWageStat: (state, _action: PayloadAction<StatReqeustPayload>) => {
      state.loading = true;
    },
    successLoadWageStat: (state, action: PayloadAction<StatResult>) => {
      state.loading = false;
      state.wage = action.payload;
    },
  },
});

export const {
  loadDutyStat,
  loadAccStat,
  loadWageStat,
  successLoadDutyStat,
  successLoadAccStat,
  successLoadWageStat,
} = slice.actions;
export const BEP = slice.name;
export default slice.reducer;
