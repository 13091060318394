import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import JwtDecode from 'jwt-decode';
import {Payload, Token, User} from '~/types/types';

type LoginState = {
  loading: boolean;
  authenticated: boolean;
  payload: Payload | undefined;
  token: string | undefined;
} & User;

let initialState: LoginState = {
  loading: false,
  authenticated: false,
  token: undefined,
  email: undefined,
  password: undefined,
  payload: undefined,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    checkLogin: (state) => {
      state.loading = true;
    },
    loginRequest: (state, action: PayloadAction<User>) => {
      const {email, password} = action.payload;
      state.email = email;
      state.password = password;
      state.loading = true;
    },
    loginSuccess: (state, action: PayloadAction<Token>) => {
      console.log(action);
      const {token} = action.payload;
      state.token = token;
      state.payload = token ? JwtDecode<Payload>(token) : undefined;
      state.loading = false;
      state.authenticated = true;
      state.email = undefined;
      state.password = undefined;
    },
    loginFailed: (state) => {
      state.loading = false;
      state.authenticated = false;
      state.token = undefined;
      state.email = undefined;
      state.password = undefined;
      state.payload = undefined;
    },
  },
});

export const {
  checkLogin,
  loginRequest,
  loginSuccess,
  loginFailed,
} = loginSlice.actions;
export const LOGIN = loginSlice.name;
export default loginSlice.reducer;
