import React, {useState, useCallback, useEffect} from 'react';
import {
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import Calendar, {Detail} from 'react-calendar';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from 'recharts';

import {loadDutyStat, loadSchStat, PATIENT} from '~/features/Patient/slice';
import {RootState} from '~/app/rootReducer';
import {
  STAT_SCALE,
  SCALE_TO_VIEW,
  SERVICE_TYPE,
  StatReqeustPayload,
} from '~/types/types';
import PeriodSelector from '~/components/PeriodSelector';
import GradeSelector from '~/components/GradeSelector';
import ServiceSelector from '~/components/ServiceSelector';
import StyledCalendar from '~/components/StyledCalendar';
import withStatTemplate, {StatProps} from '~/hoc/withStatTemplate';
import {renderDateTableHeader} from '~/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },
  filterTitle: {
    fontSize: '1rem',
    borderRightWidth: 3,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  filterContent: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  chartContainer: {
    width: '100%',
    height: '30vh',
  },
}));

const PatientTimeScreen = ({
  date,
  viewType,
  statScale,
  service,
  grade,
}: StatProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const schTime = useSelector((state: RootState) => state[PATIENT].schTime);
  const dutyTime = useSelector((state: RootState) => state[PATIENT].dutyTime);

  const [data, setData] = useState<
    ReadonlyArray<{[key: string]: string | number}>
  >();

  useEffect(() => {
    const sch = _.keys(schTime).map((key) => {
      const values = _.chain(schTime[key])
        .reduce((acc, v) => {
          return {
            ...acc,
            ...v,
          };
        }, {})
        .value();
      return {
        name: key,
        total: _.chain(values).values().sum().value(),
        ...values,
      };
    });
    const duty = _.keys(dutyTime).map((key) => {
      const values = _.chain(dutyTime[key])
        .reduce((acc, v) => {
          return {
            ...acc,
            ...v,
          };
        }, {})
        .value();
      return {
        name: key,
        total: _.chain(values).values().sum().value(),
        ...values,
      };
    });
    if (sch.length === duty.length) {
      setData(
        _.zipWith(sch, duty, (a, b) => {
          const name = a.name;
          const oA = _.chain(a)
            .omit(['name'])
            .mapKeys((_v, k) => `sch${k}`)
            .value();
          const oB = _.chain(b)
            .omit(['name'])
            .mapKeys((_v, k) => `duty${k}`)
            .value();

          return {
            name,
            ...oA,
            ...oB,
          };
        }),
      );
    }
  }, [schTime, dutyTime]);

  useEffect(() => {
    const datetime = moment(date);
    if (statScale === STAT_SCALE.MONTH) {
      datetime.month(6);
    }
    const request: StatReqeustPayload = {
      scale: statScale,
      datetime,
      serviceType: service,
    };
    dispatch(loadSchStat(request));
    dispatch(loadDutyStat(request));
  }, [dispatch, date, statScale, service]);

  const renderTableHeader = useCallback(() => {
    return renderDateTableHeader(viewType, dutyTime);
  }, [viewType, dutyTime]);

  return (
    <>
      <div className={classes.chartContainer}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{top: 20, right: 20, left: 0, bottom: 5}}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Bar
              dataKey={`sch${grade === 0 ? 'total' : grade}`}
              fill={'#4472c4'}
              name={`${grade === 0 ? '전체' : `${grade}급`} 계획`}
            />

            <Bar
              dataKey={`duty${grade === 0 ? 'total' : grade}`}
              fill={'#ed7d31'}
              name={`${grade === 0 ? '전체' : `${grade}급`} 실제`}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {renderTableHeader()}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(['sch', 'duty'], (type) => {
              return (
                <TableRow key={`row-${type}`}>
                  <TableCell>{`${grade === 0 ? '전체' : `${grade}급`} ${
                    type === 'sch' ? '계획' : '실제'
                  }`}</TableCell>
                  {_.map(data, (d) => (
                    <TableCell key={`row-body-${d.name}`} align="right">
                      {d[`${type}${grade === 0 ? 'total' : grade}`]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>초과시간</TableCell>
              {_.map(data, (d) => (
                <TableCell key={`row-diff-${d.name}`} align="right">
                  {(d[`duty${grade === 0 ? 'total' : grade}`] as number) -
                    (d[`sch${grade === 0 ? 'total' : grade}`] as number)}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStatTemplate(PatientTimeScreen, {
  grade: true,
  service: true,
});
