import {takeLatest, call, put} from 'redux-saga/effects';
import {
  loadStat,
  successLoadStat,
  loadSchStat,
  successLoadSchStat,
  loadDutyStat,
  successLoadDutyStat,
} from './slice';
import {PayloadAction} from '@reduxjs/toolkit';
import {StatReqeustPayload} from '~/types/types';
import {getPatientStat, getPatientSchStat, getPatientDutyStat} from '~/api';

function* handleStats({payload}: PayloadAction<StatReqeustPayload>) {
  try {
    const resp = yield call(getPatientStat, payload);
    yield put(successLoadStat(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleSchStats({payload}: PayloadAction<StatReqeustPayload>) {
  try {
    const resp = yield call(getPatientSchStat, payload);
    yield put(successLoadSchStat(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleDutyStats({payload}: PayloadAction<StatReqeustPayload>) {
  try {
    const resp = yield call(getPatientDutyStat, payload);
    yield put(successLoadDutyStat(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchPatient() {
  yield takeLatest(loadStat, handleStats);
  yield takeLatest(loadSchStat, handleSchStats);
  yield takeLatest(loadDutyStat, handleDutyStats);
}
