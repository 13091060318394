import {PayloadAction} from '@reduxjs/toolkit';
import {StatReqeustPayload} from '~/types/types';
import {getDutyAmountStat, getAccountStat, getWageStat} from '~/api';
import {call, put, takeLatest} from 'redux-saga/effects';
import {
  successLoadDutyStat,
  loadDutyStat,
  successLoadAccStat,
  loadAccStat,
  loadWageStat,
  successLoadWageStat,
} from './slice';

function* handleDutyStat({payload}: PayloadAction<StatReqeustPayload>) {
  try {
    const resp = yield call(getDutyAmountStat, payload);
    yield put(successLoadDutyStat(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleAccountStat({payload}: PayloadAction<StatReqeustPayload>) {
  try {
    const resp = yield call(getAccountStat, payload);
    yield put(successLoadAccStat(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleWageStat({payload}: PayloadAction<StatReqeustPayload>) {
  try {
    const resp = yield call(getWageStat, payload);
    yield put(successLoadWageStat(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export default function* watchBEP() {
  yield takeLatest(loadDutyStat, handleDutyStat);
  yield takeLatest(loadAccStat, handleAccountStat);
  yield takeLatest(loadWageStat, handleWageStat);
}
