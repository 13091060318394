import React from 'react';
import {
  TableContainer,
  Paper,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import _ from 'lodash';
import {Account} from '~/types/types';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

interface Props {
  accounts: Account[];
}

export default ({accounts}: Props) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center">일자</TableCell>
            <TableCell align="center">계정과목</TableCell>
            <TableCell align="center">세부계정</TableCell>
            <TableCell align="center">금액</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(accounts, (act) => (
            <TableRow key={act.id}>
              <TableCell align="center">{act.date}</TableCell>
              <TableCell align="center">{act.accountType?.name}</TableCell>
              <TableCell align="center">{act.accountSubType?.name}</TableCell>
              <TableCell align="center">{act.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
