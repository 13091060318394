import React, {InputHTMLAttributes} from 'react';
import {
  Field,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import {
  makeStyles,
  Container,
  CssBaseline,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginForm = (props: InjectedFormProps) => {
  const classes = useStyles();
  const {handleSubmit} = props;

  const renderTextField = (
    field: WrappedFieldProps & InputHTMLAttributes<HTMLInputElement>,
  ) => {
    const {input, type, autoFocus} = field;
    return (
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        autoFocus={autoFocus}
        label={input.name}
        type={type}
        {...input}
      />
    );
  };

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Field
            name="email"
            component={renderTextField}
            type="text"
            autoFocus
          />
          <Field name="password" component={renderTextField} type="password" />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default reduxForm({
  // a unique name for the form
  form: 'login',
})(LoginForm);
