import axios from 'axios';
import moment from 'moment';
import {
  StatReqeustPayload,
  STAT_MODE,
  NewAccountType,
  NewAccountSubType,
  NewAccount,
  STAT_SCALE,
} from '~/types/types';
import _ from 'lodash';

export const getJWTAPI = (email: string, password: string) => {
  return axios.post(
    `/users/login`,
    {email, password},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const refreshTokenAPI = (token: string) => {
  return axios.get(`/users/refresh_token`);
};

export const getScheduleStat = (body: StatReqeustPayload) => {
  return getStatistics('schedules', body);
};

export const getDutyStat = (body: StatReqeustPayload) => {
  return getStatistics('duties', body);
};

export const getDutyAmountStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  return axios.get(
    `duties/stat?year=${year}&month=${month}&day=${day}&mode=${body.scale}`,
  );
};

export const getAccountStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  return axios.get(
    `accounts/stat?year=${year}&month=${month}&day=${day}&mode=${body.scale}`,
  );
};

export const getWageStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  return axios.get(
    `accounts/wage?year=${year}&month=${month}&day=${day}&mode=${body.scale}`,
  );
};

export const getPatientStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  return axios.get(
    `patients/stat?year=${year}&month=${month}&day=${day}&mode=${body.scale}`,
  );
};

export const getPatientSchStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  return axios.get(
    `patients/stat/schedule?year=${year}&month=${month}&day=${day}&mode=${body.scale}&type=${body.serviceType}`,
  );
};

export const getPatientDutyStat = (body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  return axios.get(
    `patients/stat/duty?year=${year}&month=${month}&day=${day}&mode=${body.scale}&type=${body.serviceType}`,
  );
};

export const getStatistics = (path: string, body: StatReqeustPayload) => {
  const year = body.datetime.get('year');
  const month = body.datetime.get('month') + 1;
  const day = body.datetime.get('date');
  const resourceMap = {
    [STAT_MODE.TOTAL]: 'total',
    [STAT_MODE.EMPLOYEE]: 'employee',
    [STAT_MODE.NURSE]: 'nurse',
    [STAT_MODE.CARE]: 'care',
    [STAT_MODE.PNURSE]: 'rnurse',
    [STAT_MODE.WELFARE]: 'welfare',
  };
  return axios.get(
    `/${path}/statistics/${
      body.mode && resourceMap[body.mode]
    }?year=${year}&month=${month}&day=${day}&mode=${body.scale}&type=${
      body.jobType
    }`,
  );
};
export const getSchedules = (scale?: STAT_SCALE, date?: moment.Moment) => {
  let where = {};

  if (scale === STAT_SCALE.MONTH && date !== undefined) {
    const startDate = moment(date).startOf('month');
    const endDate = moment(date).endOf('month');
    where = {
      startDate: {gte: startDate.format()},
      endDate: {lte: endDate.format()},
    };
  }

  const filter = {
    where,
    include: [
      {relation: 'patient'},
      {relation: 'employee'},
      {relation: 'insurance'},
      {
        relation: 'duty',
      },
    ],
  };

  return axios.get(
    `/schedules?filter=${encodeURIComponent(JSON.stringify(filter))}`,
  );
};
export const getSchedule = (id: number) => {
  const filter = {
    include: [
      {relation: 'patient'},
      {relation: 'employee'},
      {relation: 'insurance'},
      {
        relation: 'duty',
      },
    ],
  };
  return axios.get(`/schedules/${id}?filter=${JSON.stringify(filter)}`);
};

export const getAccountTypes = () => {
  const filter = {
    include: [{relation: 'accountSubTypes'}],
  };
  return axios.get(`/account-types?filter=${JSON.stringify(filter)}`);
};

export const saveAccountType = (payload: NewAccountType) => {
  return axios.post(`/account-types`, payload);
};

export const saveSubAccountType = (payload: NewAccountSubType) => {
  return axios.post(`/account-sub-types`, payload);
};

export const getEmployees = () => {
  return axios.get(`/employees`);
};

export const getAccounts = () => {
  const filter = {
    include: [
      {relation: 'accountType'},
      {relation: 'accountSubType'},
      {relation: 'employee'},
    ],
  };
  return axios.get(`/accounts?filter=${JSON.stringify(filter)}`);
};

export const saveAccount = (payload: NewAccount) => {
  return axios.post(`/accounts`, payload);
};
